<template>
  <div class="PageBody">
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.PageBody {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: var(--white);
  border-radius: 10px;
  border: 1px solid var(--gray-5);
  overflow: hidden;
}
</style>
